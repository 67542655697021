// extracted by mini-css-extract-plugin
export var backlinkContainer = "s-module--backlinkContainer--cb8e1";
export var link = "s-module--link--0e4e0";
export var linkContainer = "s-module--linkContainer--607e9";
export var list = "s-module--list--59695";
export var listContainer = "s-module--listContainer--c942d";
export var listElement = "s-module--listElement--27b37";
export var listElementBold = "s-module--listElementBold--c2101";
export var listElementSpan = "s-module--listElementSpan--296bd";
export var logoText = "s-module--logoText--f3aaf";
export var paragraph = "s-module--paragraph--d16cf";
export var paragraph2 = "s-module--paragraph2--4dab7";
export var rolRow = "s-module--rolRow--b0e1f";
export var rolTitle = "s-module--rolTitle--4d94d";
export var rolTitle2 = "s-module--rolTitle2--606ed";
export var rolTitle3 = "s-module--rolTitle3--9475c";
export var rolTitle4 = "s-module--rolTitle4--16828";
export var sectionContainer = "s-module--sectionContainer--eb39f";
export var sectionContent = "s-module--sectionContent--f54fc";
export var textRevealAnimation = "s-module--textRevealAnimation--e22ee";
export var title = "s-module--title--2d17e";