import React from 'react';

import { LogoText } from '../Icon';
import GradientChips from '../../layout/GradientChips';
import MSTable from '../HowToPlay/MSTable';

import * as s from './s.module.css';

export const Roles = {
};

interface Props {
  changeRol: (rol: string) => void,
}

const OpenRoles: React.FunctionComponent<Props> = ({ changeRol }) => (
  <>
  </>
);

export const Components = {
};

export const careersNavigationData = [{
  title: "Open Roles",
  id: 'item-1'
}]

interface State {
  activeRol: string,
}

class Careers extends React.Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeRol: null,
    }
  }

  changeRol = (activeRol: string) => {
    this.setState({ activeRol });
  };

  goBackToCareers = () => {
    this.setState({ activeRol: null });
  };

  render() {
    let RolComponent = null;
    if (this.state.activeRol) {
      RolComponent = Components[this.state.activeRol];
    }
    return (
      <section className={s.sectionContainer}>
        <div className={s.sectionContent}>
          <h3 className={s.title}>Careers</h3>
          {
            RolComponent
              ? <RolComponent goBackToCareers={this.goBackToCareers} />
              : <MSTable
                contentData={[{
                  customComponent: () => <OpenRoles changeRol={this.changeRol} />,
                  navigationreferenceid: "#item-1",
                }]}
                navigationData={careersNavigationData}
              />
          }
        </div>
      </section>
    )
  }
}

export default Careers;