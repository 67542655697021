import React from "react";
import Layout from "../layout/default";
import Seo from "../components/seo";
import Careers from '../components/Careers';

const CareersPage = ({ location, pageContext }) => (
    <Layout location={location} dynamicDefaultPaths={pageContext.dynamicDefaultPaths}>
        <Seo title="Careers" />
        <Careers />
    </Layout>
)

export default CareersPage;
